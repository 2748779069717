<template>
  <section class="page-container">
    <div class="flex-col header text-left fw-500  color-dark">
      <span class="lh-150 fs-26">Racing Max</span>
      <span class="lh-150 fs-42 ">鴻朗保險</span>
    </div>
    <div class=" content">
      <div class="text-left flex-col">
        <div class="flex-col color-dark">
          <span class="fs-22 lh-150">Login</span>
          <span class="fs-32 lh-150">登入</span>
        </div>
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
        >
          <div
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span >Account ID</span>
              <span>帳戶名稱</span>
            </div>
            <el-form-item
              ref="username"
              prop="username"
              :rules="rules.input">
              <v-input
                v-model="formData.username"
                :is-upper="false"></v-input>
            </el-form-item>
          </div>
          <div
            class="form-item form-half">
            <div
              class="flex-col text-left">
              <span >Login Password</span>
              <span>登入密碼</span>
            </div>
            <el-form-item
              ref="password"
              prop="password"
              :rules="rules.input">
              <v-input
                v-model="formData.password"
                is-suffix-icon
                :is-upper="false"
                :maxlength="8"
                :type="inputType">
                <template  slot="suffix">
                  <font-awesome-icon
                    v-if="inputType=='password'"
                    icon="eye-slash"
                    @click="handleClose"></font-awesome-icon>
                  <font-awesome-icon
                    v-else
                    icon="eye"
                    @click="handleOpen"></font-awesome-icon>
                </template>
              </v-input>
            </el-form-item>
          </div>
        </el-form>
        <span class="mg-t-25">
          <v-button @click="handleLogin">Login 登入</v-button>
        </span>
      </div>
    </div>
    <div class="flex img-flex">
      <img
        class="img__1"
        src="../assets/images/index/vehicle_1.png"
        alt="">
      <img
        class="img__2"
        src="../assets/images/index/vehicle_2.png"
        alt="">
      <img
        class="img__3"
        src="../assets/images/index/vehicle_3.png"
        alt="">
      <img
        class="img__square3"
        src="../assets/images/index/square_3.png"
        alt="">
      <img
        class="img__square2"
        src="../assets/images/index/square_2.png"
        alt="">
      <img
        class="img__square1"
        src="../assets/images/index/square_1.png"
        alt="">
    </div>
  </section>
</template>

<script>
import MixinRules from '@/mixins/rules'
import {login} from '@/api/index'
import {mapActions} from 'vuex'
export default {
  name: 'index',
  mixins: [MixinRules],
  data(){
    return{
      formData:{
        username:'',
        password:''
      },
      inputType:'password'
    }
  },
  methods:{
    ...mapActions('quotation',['setQuoteInformationForm']),

    handleOpen(){
      this.inputType = 'password'
    },
    handleClose(){
      this.inputType = 'text'
    },
    handleLogin(){
      this.$refs.form.validate((valid, rules) => {
        if(valid){
          this.network().login()

        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network() {
      return {
        login:async ()=>{
          const { data } = await login({
            ...this.formData
          })
          localStorage.setItem('token', data.token)
          const { fullPath = '' } = this.$route.query
          if(fullPath) {
            this.$router.replace(fullPath)
          } else {
            this.setQuoteInformationForm(null)
            this.$router.replace('/quoting/policy/details')
          }
          window.scrollTo({top: 0, behavior:'smooth'})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  height: 100vh;
  position: relative;
  max-width: 856px;
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  .header{
    padding:38px 0 0 32px;
    flex: 1;
  }
  .content{
    padding: 0px 48px 0px 32px;
    //transform: translateY(100%);
    //position: relative;
    //z-index: 999;
    flex:2;
    z-index: 99;
    @media screen and (max-width: 768px) {
      //transform: translateY(20%);
      img{
        width: 169px;
      }
    }
    //@media screen and (min-height:825px ) and (max-height:1000px ){
    //  transform: translateY(25%);
    //}
    //@media screen and (max-height:824px ){
    //  transform: translateY(20%);
    //}
  }
  .img-flex{
    flex: 2;
    .img{
      &__1{
        position: absolute;
        bottom:0;
        //height: 369.34px;
        width: 49%;
      }
      &__2{
        position: absolute;
        left: 12.96%;
        bottom: -0.08%;
        width: 65%;
        //height: 429px;

      }
      &__3{
        position: absolute;
        //height: 369.34px;
        right: 0;
        bottom: 5px;
        //width: 49%;
        width: 58%;
      }
      &__square1{
        position: absolute;
        right: 0;
        bottom:0;
        width: 70%;
      }
      &__square2{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 61%;
      }
      &__square3{
        position: absolute;
        right:0;
        bottom: 0;
        width: 50%;
      }
    }
  }
}
</style>
